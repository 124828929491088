/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
//Bibliotecas que serão usadas no projeto, externa do react

import "lazysizes"
require("prismjs/themes/prism-okaidia.css") 